/** 
==Start of Carousel styling==
*/
.carousel .rec-pagination .rec-dot_active{
    background-color: #515050;
    box-shadow: 0 0 1px 2px rgb(0, 0, 0);
}

/* to put back if more apps have been added */
.carousel button {
    display: none;
}

.carousel button:hover{
    box-shadow: 0 0 1px 2px #C00000;
}

.carousel button:hover:enabled{
    border-radius: none;
    box-shadow: black;
    background-color: white;
}

/** 
==End of Carousel styling==
*/

.footerLogo{
    margin-left: -20px;
}